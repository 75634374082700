

.button-right{
  text-align: right
}

.modal-separator{
  height: 1px; 
  border-width: 0; 
  background-color: gray;
  min-width: 750px
}

.modal-lable{
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: initial;
}