body {
    font-size: 14px;
}

.form-select{
    font-size: 0.9rem; 
}

.form-control{
    font-size: 0.9rem; 
}

label{
    font-weight: 700;
}

/* Wrapper - All pages */
.pagewrap{
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}
.page-content {
  flex-grow: 1;
}

.top-bar {
  background-color: #FFFFFF;
}

.tb-navbar {
  background-color: #1B1B57 !important;
}

.tb-navbar-gap {
  background-color: #1B1B57 !important;
}

.app-logo {
  height: auto;
  width: 45px;
}

.fake-btn-primary {
  color: #fff;
  background-color: #3333a3;
  border-color: #3333a3;
}

.fake-btn-primary-gap {
  color: #fff;
  background-color: #3066be;
  border-color: #3066be;
}

.custom-navbar .navbar-nav .nav-link {
  color: #c6cace;
}
.custom-navbar .navbar-nav .nav-link:hover {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
  color: #fff;
  border-bottom: 1px solid #fff;
}
.logo {
  height: 30px;
  width: auto;
}
@media (min-width: 768px) {
  .logo {
    height: 36px;
    width: auto;
 }
 }
 .logotype {
  font-size: 2rem;
}
@media (min-width: 768px) {
  .logotype {
    font-size: 2.5rem;
  }
 }
 @media (min-width: 768px) {
  .nav-dropdown {
    width: 320px;
  }
}
.user-profile-avatar {
  font-size: 1rem;
  display: inline-flex;
  width: 36px;
  height: 36px;
  color: #fff;
  border-radius: 50%;
  background-color: #211815;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.mobile-menu .navbar-toggler {
  padding: 0;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: none;
  border-radius: 0;
  transition: box-shadow .15s ease-in-out;
}
.hamburger-box {
  width: 21px;
  height: 24px;
  display: inline-block;
  position: relative;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 21px;
  height: 1px;
  background-color: #fff;
  border-radius: 0px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
  background-color: #fff;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: 2px;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}
/* Will be reused in all modals/offcanvas */
.custom-btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  /* background: transparent url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e) center/1em auto no-repeat; */
  border: 0;
  border-radius: 0;
  opacity: 1;
}
/* Hero Carousel */
.hero-carousel .carousel-inner .carousel-item img {
  height: 400px;
  max-height: 400px;
  object-fit: cover;
}
@media (min-width: 768px) {
 .hero-carousel .carousel-inner .carousel-item img {
  height: 650px;
  max-height: 650px;
  object-fit: cover;
}
}
.hero-carousel .carousel-caption {
  position: absolute;
  right: 5%;
  top: 20px;
  left: 5%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
/* Model Name */
.model-name {
  height: 40px;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.model-category-name {
  font-size: 9px;
  color: #595968;
}
.submitter-avatar {
  font-size: 1rem;
  display: inline-flex;
  width: 36px;
  height: 36px;
  color: #fff;
  border-radius: 50%;
  background-color: #211815;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.recaptcha {
  height: 72px;
  width: auto;
  transition: 0.3s;
}

.tabs-nih {
  overflow-x: auto;
  overflow-y: hidden;
}

.tabs-nih .nav-link {
  color: #343a40;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tabs-nih .nav-item.show .nav-link, .tabs-nih .nav-link.active {
  color: #336195;
  background-color: #ffffff;
  border-color: #ffffff;
  border-bottom: 5px solid #336195;
}

.bg-accent-gold {
  background-color: #f1e5cd;
}
.bg-accent-blue {
  background-color: #5388c4;
}
.bg-accent-lightblue {
  background-color: #d9f1ff;
}

.submit-instructions .dropdown-menu {
  left: 50% !important;
  transform: translateX(-50%) !important;
  top: 100% !important;
}


.custom-carousel .carousel-inner .carousel-item img {
  height: 300px;
  max-height: 300px;
object-fit: contain;
}

@media (min-width: 768px) {
  .custom-carousel .carousel-inner .carousel-item img {
    height: 600px;
    max-height: 600px;
  object-fit: contain;
  }
 }

.custom-carousel .carousel-indicators .active {
opacity: 1;
background-color: #f2f2f7;
}

.custom-carousel .carousel-indicators {
bottom: -120px;
position: absolute;
right: 0%;
left: 0%;
z-index: 2;
display: flex;
justify-content: flex-start;
padding: 0;
margin-right: 0;
margin-bottom: 1rem;
margin-left: 0;
list-style: none;
white-space: nowrap;
width: 100%;
overflow: auto;
}

.custom-carousel .carousel-indicators::-webkit-scrollbar {
height: 5px;
}

.custom-carousel .carousel-indicators::-webkit-scrollbar-track {
background: #212529;
}
.custom-carousel .carousel-indicators::-webkit-scrollbar-thumb {
background: #f8f9fa;
}

.thumbnail-button {
width: 72px !important;
height: 72px !important;
padding: .5rem;
border-radius: .5rem;
}

.carousel-thumbnail {
width: 72px;
height: 72px;
padding: .5rem;
border-radius: .5rem;
object-fit: cover;
}

.carousel-control-next, .carousel-control-prev {
position: absolute;
background-color:transparent;
top: 0;
bottom: 0;
z-index: 1;
display: flex;
align-items: center;
justify-content: center;
width: 15%;
padding: 0;
color: #fff;
text-align: center;
border: 0;
opacity: .5;
transition: opacity .15s ease;
}

.carousel-control-next {
right: -100px;
}

.carousel-control-prev {
left: -100px;
} 

 /* Main content */
  
  .starter-template {
    padding: 40px 15px;
    text-align: center;
  }
  
  .gap-pin-code {
    text-align: center;
  }
  
  
  .pincode-email {
      color: white;
  }
  
  .gap-apps-inner {
      border: 1px solid red;
  }
  
  .gap-apps-icon {
      text-align:center;
  }
  
  .gap-page-content {
      margin-top: 20px;
      background: white;
      padding-bottom: 3rem;
  }
  
  .selected {
      background-color:lightgray;
      font-weight:bold;
  }
  
  a { cursor: pointer; }
  
  .ngdialog.ngdialog-theme-default.custom-width-400 .ngdialog-content {
      width: 400px;
  }
  .ngdialog.ngdialog-theme-default.custom-width-500 .ngdialog-content {
      width: 500px;
  }
  
  .ngdialog.ngdialog-theme-default.custom-width-600 .ngdialog-content {
      width: 600px;
  }
  
  .ngdialog.ngdialog-theme-default.custom-width-700 .ngdialog-content {
      width: 700px;
  }
  
  .disabled {
     pointer-events: none;
     cursor: default;
  }
  
  
  .custom_fieldset{
    border: 1px solid #ddd;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    border-radius: 5px;
  }
  
  .custom_legend {
          font-size: 1.2em !important;
          font-weight: bold !important;
          text-align: left !important;
          width:auto;
          padding:0 10px;
          border-bottom:none;
  }
  
  .ng-table th.sortable .sort-indicator {
      display: inline-block;
  }
  
  
  a.adisabled {
    cursor: not-allowed;
    opacity: .65;
  }
  
  a.adisabled:hover { text-decoration: none; }
  a.adisabled:focus { text-decoration: none; }
  a.adisabled:active { text-decoration: none; }
  
  .gap-logo {
     width: 285px;
      height: auto;
  }
  
  @media (min-width: 768px) {
      .gap-logo {
         width: 285px;
          height: auto;
      }
  }
  
  .custom-menu {
              display: none;
              z-index: 1000;
              position: absolute;
              overflow: hidden;
              border: 1px solid #CCC;
              white-space: nowrap;
              font-family: sans-serif;
              background: #FFF;
              color: #333;
              border-radius: 5px;
              padding: 0;
          }
   /* Each of the items in the list */
   .custom-menu li {
              padding: 2px 8px; 
              cursor: pointer;
              list-style-type: none;
              transition: all .3s ease;
              user-select: none;
          }
  
  .custom-menu li:hover {
       background-color: #DEF;
  }
   
  hr {
      margin-bottom: 10px;
      margin-top: 10px;
      background-color: #778899;
  }
  
  /*
  .well {
      border-radius: 1px;
      background-image: none;
      background-color: #fff;
      box-shadow: none;
      border: none;
      min-height: 20px;
  }
  */
  
  /*#region Padding Styles*/
  .padding-0 {
      padding: 0 !important;
  }
  
  .padding-top-0 {
      padding-top: 0 !important;
  }
  
  .padding-top-1 {
      padding-top: 1px !important;
  }
  
  .padding-top-2 {
      padding-top: 2px !important;
  }
  
  .padding-top-3 {
      padding-top: 3px !important;
  }
  
  .padding-top-4 {
      padding-top: 4px !important;
  }
  
  .padding-top-5 {
      padding-top: 5px !important;
  }
  
  .padding-top-7 {
      padding-top: 7px !important;
  }
  
  .padding-top-10 {
      padding-top: 10px !important;
  }
  
  .padding-top-15 {
      padding-top: 15px !important;
  }
  h
  .padding-top-25 {
      padding-top: 25px !important;
  }
  
  .padding-top-30 {
      padding-top: 30px !important;
  }
  
  
  .padding-bottom-0 {
      padding-bottom: 0 !important;
  }
  
  .padding-bottom-1 {
      padding-bottom: 1px !important;
  }
  
  .padding-bottom-2 {
      padding-bottom: 2px !important;
  }
  
  .padding-bottom-3 {
      padding-bottom: 3px !important;
  }
  
  .padding-bottom-4 {
      padding-bottom: 4px !important;
  }
  
  .padding-bottom-5 {
      padding-bottom: 5px !important;
  }
  
  .padding-bottom-7 {
      padding-bottom: 7px !important;
  }
  
  .padding-bottom-10 {
      padding-bottom: 10px !important;
  }
  
  .padding-bottom-15 {
      padding-bottom: 15px !important;
  }
  
  .padding-bottom-25 {
      padding-bottom: 25px !important;
  }
  
  .padding-bottom-30 {
      padding-bottom: 30px !important;
  }
  
  
  .padding-right-0 {
      padding-right: 0 !important;
  }
  
  .padding-right-1 {
      padding-right: 1px !important;
  }
  
  .padding-right-2 {
      padding-right: 2px !important;
  }
  
  .padding-right-3 {
      padding-right: 3px !important;
  }
  
  .padding-right-4 {
      padding-right: 4px !important;
  }
  
  .padding-right-5 {
      padding-right: 5px !important;
  }
  
  .padding-right-7 {
      padding-right: 7px !important;
  }
  
  .padding-right-10 {
      padding-right: 10px !important;
  }
  
  .padding-right-15 {
      padding-right: 15px !important;
  }
  
  .padding-right-25 {
      padding-right: 25px !important;
  }
  
  .padding-right-30 {
      padding-right: 30px !important;
  }
  
  
  .padding-left-0 {
      padding-left: 0 !important;
  }
  
  .padding-left-1 {
      padding-left: 1px !important;
  }
  
  .padding-left-2 {
      padding-left: 2px !important;
  }
  
  .padding-left-3 {
      padding-left: 3px !important;
  }
  
  .padding-left-4 {
      padding-left: 4px !important;
  }
  
  .padding-left-5 {
      padding-left: 5px !important;
  }
  
  .padding-left-7 {
      padding-left: 7px !important;
  }
  
  .padding-left-10 {
      padding-left: 10px !important;
  }
  
  .padding-left-15 {
      padding-left: 15px !important;
  }
  
  .padding-left-25 {
      padding-left: 25px !important;
  }
  
  .padding-left-30 {
      padding-left: 30px !important;
  }
  /*#endregion Padding Styles*/
  
  /*#region Margin Styles*/
  .margin-0 {
      margin: 0 !important;
  }

  .margin-10 {
    margin: 10px !important;
  }
  
  .margin-top-0 {
      margin-top: 0 !important;
  }
  
  .margin-top-1 {
      margin-top: 1px !important;
  }
  
  .margin-top-2 {
      margin-top: 2px !important;
  }
  
  .margin-top-3 {
      margin-top: 3px !important;
  }
  
  .margin-top-4 {
      margin-top: 4px !important;
  }
  
  .margin-top-5 {
      margin-top: 5px !important;
  }
  
  .margin-top-7 {
      margin-top: 7px !important;
  }

  .margin-top-8 {
    margin-top: 8px !important;
}
  
  .margin-top-10 {
      margin-top: 10px !important;
  }
  
  .margin-top-15 {
      margin-top: 15px !important;
  }

  .margin-top-20 {
    margin-top: 20px !important;
}
  
  .margin-top-25 {
      margin-top: 25px !important;
  }
  
  .margin-top-30 {
      margin-top: 30px !important;
  }
  
  
  .margin-bottom-0 {
      margin-bottom: 0 !important;
  }
  
  .margin-bottom-1 {
      margin-bottom: 1px !important;
  }
  
  .margin-bottom-2 {
      margin-bottom: 2px !important;
  }
  
  .margin-bottom-3 {
      margin-bottom: 3px !important;
  }
  
  .margin-bottom-4 {
      margin-bottom: 4px !important;
  }
  
  .margin-bottom-5 {
      margin-bottom: 5px !important;
  }
  
  .margin-bottom-7 {
      margin-bottom: 7px !important;
  }
  
  .margin-bottom-10 {
      margin-bottom: 10px !important;
  }
  
  .margin-bottom-15 {
      margin-bottom: 15px !important;
  }

  .margin-bottom-20 {
    margin-bottom: 20px !important;
}
  
  .margin-bottom-25 {
      margin-bottom: 25px !important;
  }
  
  .margin-bottom-30 {
      margin-bottom: 30px !important;
  }
  
  
  .margin-right-0 {
      margin-right: 0 !important;
  }
  
  .margin-right-1 {
      margin-right: 1px !important;
  }
  
  .margin-right-2 {
      margin-right: 2px !important;
  }
  
  .margin-right-3 {
      margin-right: 3px !important;
  }
  
  .margin-right-4 {
      margin-right: 4px !important;
  }
  
  .margin-right-5 {
      margin-right: 5px !important;
  }
  
  .margin-right-7 {
      margin-right: 7px !important;
  }
  
  .margin-right-10 {
      margin-right: 10px !important;
  }
  
  .margin-right-15 {
      margin-right: 15px !important;
  }

  .margin-right-15 {
    margin-right: 15px !important;
}
  
  .margin-right-20 {
      margin-right: 20px !important;
  }
  
  .margin-right-30 {
      margin-right: 30px !important;
  }
  
  .margin-left-0 {
      margin-left: 0 !important;
  }
  
  .margin-left-1 {
      margin-left: 1px !important;
  }
  
  .margin-left-2 {
      margin-left: 2px !important;
  }
  
  .margin-left-3 {
      margin-left: 3px !important;
  }
  
  .margin-left-4 {
      margin-left: 4px !important;
  }
  
  .margin-left-5 {
      margin-left: 5px !important;
  }
  
  .margin-left-7 {
      margin-left: 7px !important;
  }

  .margin-left-9 {
    margin-left: 9px !important;
}
  
  .margin-left-10 {
      margin-left: 10px !important;
  }
  
  .margin-left-15 {
      margin-left: 15px !important;
  }
  
  .margin-left-25 {
      margin-left: 25px !important;
  }
  
  .margin-left-30 {
      margin-left: 30px !important;
  }

  .margin-left-95 {
    margin-left: 95px !important;
  }
  
  .margin-left-105 {
    margin-left: 105px !important;
  }
  
  .margin-left--15 {
      margin-left: -15px !important;
  }

  .margin-left--10{
    margin-left: -10px !important;
  }

  .margin-top--10 {
    margin-top: -10px !important;
}

.margin-left--80 {
    margin-left: -80px !important;
}

  .floatright {
    float:right
  }
  

  
  .hr-text-content{
      background-color: #fff;
      padding:0 12.5px;
  }
  
  
  .ng-table-scrollcontainer {
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 10px;
    border: solid lightgray 0.2px;
  }
  
  .ng-table-scrollcontainer table td:first-child, thead th:first-child {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 1;
  }


.labelhyperlink {
    cursor:pointer;
    color:blue;
    text-decoration:underline;
}

.tablecellwrap {
    overflow-wrap: break-word;
}

.tableColumnSort {
    display: block;
}

.buttonToLink {
    font-Size: '1em';
    text-align: 'left';
    color: blue;
    background: transparent;
    margin: 0;
    padding: 0;
    border: 0;
    cursor: 'pointer';
}

/**
 * Main wrapper
 */
 .select-search {
    width: 300px;
    position: relative;
    font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 10;
}

.select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
}

/**
 * Input
 */
.select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 40px 0 16px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
    list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #2FCC8B;
    color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #2eb378;
    color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #2FCC8B;
}


.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 20000;
    top: 36px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
    background-color: lightgray;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}

.preOut {
    overscroll-behavior-y: auto; 
    overscroll-behavior-x: auto; 
    max-height:400px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f5f5f5;
    color: #333;
    font-size: 13px;
    line-height: 1.42857143;
    padding: 10px;
  }

  .hyperlinkCSS {
    
    cursor: pointer; 
    /*color: #337ab7;*/
    color: #1266F1
  }

  .buttonLink {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #1266F1;
    text-decoration: underline;
    cursor: pointer;
  }

.breadcrumb_custom{
    padding-top: 8px;
    padding-left: 10px;
    
}

.modal p {
    word-wrap: break-word;
    white-space: normal;
}