.comparebutton {
  float:right;
  margin-bottom: 10px;
}

.displaylable{
  font-weight: bold;
  margin-top: 20px;
}

.comparedtable{
  border: solid 1px black;
  padding: 5px
}

.checkboxloc {
  vertical-align: middle;
}

.rightline {
  border-right: 1px solid black;
}

.fileimage {
  display: inline-block; 
  vertical-align: middle;
  width: 20px
}

.googlefilescard{
  overflow-y: scroll; 
  height:250px; 
  padding:0px;
}

.ncbienainfo{
    margin-top: 8px;
    margin: 4px, 4px;
    padding: 4px;
    width: 600px;
    height: 170px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
}