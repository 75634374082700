.background_white {
    background: white
  }
  
  
  .right-float{
    float: right;
    vertical-align:center
  }

  .calculatebutton{
    text-align: right; 
    clear: both; 
    float: right; 
    margin-top: 15px;  
    margin-right: 15px;

  }

  .searchableLay1{
    z-index: 20001; 
   }

.searchableLay2{
  z-index: 20000; 
 }

 .sampleDistance{
  font-size: 18px; 
  font-weight: bold; 
  font-family: 'Open Sans', sans-serif; 
  color: black
 }

 .sampleDistanceDownload{
  float:right; 
  padding-bottom: 10px
 }

 .text-align{
  text-align: right
 }

 .distance-display {
  display: inline-block;
  width: 90%;
 }

 .distance-layout{
  height: 600px; 
  border: 1px solid lightgray;
 }

 .legend-display {
  display: inline-block; 
  width: 10%; 
  height: 600px;
  
 }

 .legend-layout {
   height: 100%;
   vertical-align: top;
   margin-left: -50px;
 }

 .differnt_title{
    font-size: 18px; 
    font-weight: bold; 
    font-family: 'Open Sans', sans-serif; 
    color: black;
 }