.groupbutton {
  text-decoration:underline;
  cursor: pointer; 
  color: #337ab7; 
  margin-left:5px
}


.vcflist{
  border:2px solid #ccc; 
  width:700px; 
  height: 180px; 
  overflow-y: scroll; 
   
  padding:10px
}

.label_middle_left{
  display: table;
  margin-left: -20px;
}

.label_middle_right{
  display: table;
  margin-right: -20px;
}

.label_middle_right1{
  display: table;
  margin-right: -50px;
}

.span_middle{
  display:table-cell;
  vertical-align: middle;
}

.main_menu-right{
  text-align: right;
  margin-top: 20px;
}