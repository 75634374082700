.Header-float-right {
  display: flex;
  min-width: 350px;
}

.Header-background {
  background-color: #1B1B57 !important;
}

.modal-backdrop.show {
  opacity: 0.7;
  background: #f0f0f0;
}

.text-align-left{
  text-align: left;
}

.redcolor {
  color:red;
  overflow-wrap: break-word;
  white-space: pre;
}

.float_left_margin-left-20{
  float:left; 
  margin-left:20px;
}

.label_right{
  text-align:right; 
  width:105px
}

.nameLabelCss{
  width:150px; 
}

.block-ui-message{
  color: #333;
  font-size: x-large;
  display: inline-block;
}

.blockspincolor{
  color: #02a17c;
}

.nav-link-over{
  margin-left: -8px;

}

.hr-text-container{
  width:100%;
  text-align: center;
  border-bottom:1px solid rgba(0,0,0,.1);
  line-height:.1em;
  margin-top: 40px;
}


.dropdown-menu li {
  position: relative;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}

  .dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
  }
  .dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
  }

  .headcaret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    transform: rotate(270deg);
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    position: absolute;
    margin-top: -2px;
    right: 8px;
    top: 50%;
}

.top-bar-text {
  width: 209px;
  height: 12px;

  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-size: .875em;
  line-height: 12px;
  /* identical to box height, or 120% */

  /* Color Fill/Black */
  color: #000000;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.dropdown_menu_location{
  margin-right: 0px;
  padding-right: 0px !important;
}

.dropdown_icon_location{
  margin-right: 0px;
  padding-left: 3px !important;
}
