
 
  
  .right-float{
    float: right;
    vertical-align:center
  }

  .group_title_font{
    font-size: large;
  }