.background_white {
    background: white
  }
  
  
  .right-float{
    float: right;
    vertical-align:center
  }

.filterbutton{
  margin-left:10px;
  display: inline-block;
}

.downloadbutton{
  float:right;
  margin-bottom: 10px;
}

.chartbar{
  border-right: 1px solid;
}

.impactcheckbox{
  display: inline-block; 
  width:50%;
  font-size: 14px;
  font-weight: bold;
}