.home-page-content {
  background-color: #E9ECEF;
  padding-bottom: 3rem;
}


.gap-thumbnail {
  display: block;
  text-align: center;
  text-decoration: none;
  border: none;
  padding: 10px;
  background-color: #FFFFFF;
  color:#000000;
}

.gap-thumbnail:hover,
.gap-thumbnail:focus {
  text-decoration: none;
  /*border: none;*/
}

.thumbnail .gap-app-icon {
  text-align: center;
  height: 55px;
}


.gap-hero-title {
    color: #1B1B57;
    font-size: 30px;
}

.gap-hero-subtitle {
    color: #1B1B57;
    font-size: 16px;
}

.gap-app-names {
    font-family: "Public Sans";
    text-align: center;
    font-weight: 700;
    color: #000000;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 23px;
    margin-top: 15px;
}

.gap-app-desc {
    text-align: center;
    color: #000000;
    font-size: 15px;
}

.individual-app-icons {
  height: 60px;
}

.shutdown_banner{
  font-weight:bold;
  margin-top: 10px;
}